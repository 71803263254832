@import './variables.scss';
@import './metronic-theme.scss';

body {
    /*Cutom Only*/
    .kt-splash-screen {
      background-color: #fff;
    }

    .grid-screen, .card-screen, .form-screen {
      > .card-header  {
        .grid-screen-title, .card-screen-title, .form-screen-title {
          &, i {
            color: #3B4F64;
          }
        }
      }
    }

    .modal-view-only {
      .modal-body {
        &::after {
          content: "";
          display: block;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
        }
      }

      .modal-footer {
        .btn[type="submit"] {
          display: none !important;
        }
      }
    }

    .modal-force {
      .modal-header {
        .close {
          display: none;
        }
      }

      .modal-footer {
        .btn[type="button"] {
          display: none;
        }
      }
    }

    /*Cutom Only*/
}